import {
  createSlice, createAsyncThunk, SerializedError, miniSerializeError,
} from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { APIStatus } from '../../enums/APIStatus';
import { IRootState } from '../store';
import {
  getMatchesFromLeague,
  deleteMatch,
  editMatch,
  EditMatch,
  postMatch,
  getPaginatedMatchesFromLeague,
  importRecordingsToZola,
  DateRange,
} from '../../apis/privateApi';
import {
  LeagueRecording, Recordings, RecordingsPaginationCursors, RecordingsPaginationPages,
} from '../../types/recordings.type';
import { Message } from '../../types/message.type';
import { MatchImportData } from '../../types/match_import.type';
import { MessageType } from '../../enums/MessageType';
import { Cursor, Page } from '../../types/pagination.type';
import Cursors from '../../utils/pagination/cursors';
import Pages from '../../utils/pagination/pages';

const initialState = {
  message: {} as Message,
  status: APIStatus.IDLE,
  recordings: {} as Recordings,
  paginationCursors: {} as RecordingsPaginationCursors,
  paginationPages: {} as RecordingsPaginationPages,
};

type deleteRecType = {
  leagueId: string,
  recordingId: string;
  recordingTitle: string
};

type editRecType = {
  leagueId: string,
  matchId: string,
  updatedValues: EditMatch
};

type Match = {
  team_home_id: string,
  team_away_id: string,
  team_home_score: number,
  team_away_score: number,
  duration: number;
  recorded_date: string,
  owner_id: string,
  url: string;
};

type recordingPosted = {
  match: Match,
  leagueId: string
};

type importData = {
  data: MatchImportData,
  leagueId: string
};

export const fetchLeagueRecordings = createAsyncThunk('leagueRecordings', async ({
  leagueId,
  filteredTeam,
  dateRange,
}: {
  leagueId: string
  filteredTeam?: string
  dateRange?: DateRange
}, { getState }) => getMatchesFromLeague(leagueId, filteredTeam, dateRange)
  .then((response) => {
    const state = getState() as IRootState;
    const { currentLeague } = state.leagueReducer;

    const paginationCursors = response.headers.link ? Cursors.fromLinkHeader(response.headers.link) : {
      before: `leagues/${leagueId}/matches`,
      after: `leagues/${leagueId}/matches`,
    };

    const paginationPages = response.headers.link ? Pages.fromLinkHeader(response.headers.link) : {
      first: `leagues/${leagueId}/matches?page=1${filteredTeam && `&per_page=100&team_name=${filteredTeam}`}`,
      next: `leagues/${leagueId}/matches?page=2${filteredTeam && `&per_page=100&team_name=${filteredTeam}`}`,
    };

    return {
      currentLeague,
      recordings: response.data.data,
      paginationCursors,
      paginationPages,
    };
  })
  .catch((error) => {
    throw error;
  }));

export const fetchMoreLeagueRecordings = createAsyncThunk('moreLeagueRecordings', async ({
  leagueId,
  cursor,
  page,
}: { leagueId: string, cursor?: Cursor, page?: Page }, { getState }) => {
  const state = getState() as IRootState;
  let url: string | undefined;
  if (cursor) {
    url = state.recordingsReducer.paginationCursors[leagueId][cursor];
  } else if (page) {
    url = state.recordingsReducer.paginationPages[leagueId].next;
  }

  return getPaginatedMatchesFromLeague(url!)
    .then((response) => ({
      currentLeague: leagueId,
      recordings: response.data.data,
      page,
      cursor,
      paginationCursors: Cursors.fromLinkHeader(response.headers.link),
      paginationPages: Pages.fromLinkHeader(response.headers.link),
    }
    ))
    .catch((error) => {
      throw error;
    });
});

export const deleteLeagueRecording = createAsyncThunk('deleteRecording', async ({
  leagueId,
  recordingId,
  recordingTitle,
}: deleteRecType) => deleteMatch(leagueId, recordingId)
  .then(() => recordingTitle)
  .catch((error) => {
    throw error;
  }));

export const editLeagueRecording = createAsyncThunk('editRecording', async ({
  leagueId,
  matchId,
  updatedValues,
}: editRecType) => {
  await editMatch(leagueId, matchId, updatedValues)
    .then((res) => res.data);
});

export const postLeagueRecording = createAsyncThunk('postLeagueRecording', async ({
  match,
  leagueId,
}: recordingPosted) => {
  await postMatch(match, leagueId)
    .then((res) => res.data);
});

export const importRecordingToClub = createAsyncThunk('importRecordingToClub', async ({
  data,
  leagueId,
}: importData) => importRecordingsToZola(data, leagueId)
  .then((res) => res.data), {
  serializeError: (error: unknown) => {
    if (isAxiosError(error)) {
      return {
        message: error.response?.data.message,
        name: error.name,
        stack: error.stack,
        code: error.code,
      } satisfies SerializedError;
    }

    return miniSerializeError(error);
  },
});

const ensureUniqueRecordings = (recordings: LeagueRecording[]) => {
  const unique_recording_ids: string[] = [];
  const unique_recordings: LeagueRecording[] = [];

  recordings.forEach((rec: LeagueRecording) => {
    if (!unique_recording_ids.includes(rec.public_identifier)) {
      unique_recording_ids.push(rec.public_identifier);
      unique_recordings.push(rec);
    }
  });

  return unique_recordings;
};

const recordingsSlice = createSlice({
  name: 'recordings',
  initialState,
  reducers: {
    clearRecordingMessage: (state) => {
      state.message = {} as Message;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeagueRecordings.pending, (state) => {
        state.status = APIStatus.PENDING;
      })
      .addCase(fetchLeagueRecordings.fulfilled, (state, action) => {
        state.status = APIStatus.FULFILLED;
        const {
          currentLeague,
          recordings,
          paginationCursors,
          paginationPages,
        } = action.payload;
        if (currentLeague) {
          state.recordings[currentLeague.public_identifier] = recordings;
          state.paginationCursors[currentLeague.public_identifier] = paginationCursors;
          state.paginationPages[currentLeague.public_identifier] = paginationPages;
        } else {
          // TODO: maybe throw an error here
          state.status = APIStatus.REJECTED;
        }
      })
      .addCase(fetchLeagueRecordings.rejected, (state) => {
        state.status = APIStatus.REJECTED;
      })
      .addCase(fetchMoreLeagueRecordings.fulfilled, (state, action) => {
        const {
          currentLeague,
          recordings,
          cursor,
          paginationCursors,
          page,
          paginationPages,
        } = action.payload;

        if (currentLeague && state.recordings[currentLeague]) {
          const recordings_array = (cursor === 'after' || page === 'next') ? [
            ...state.recordings[currentLeague],
            ...recordings,
          ] : [
            ...recordings,
            ...state.recordings[currentLeague],
          ];

          state.recordings[currentLeague] = ensureUniqueRecordings(recordings_array);

          if (cursor) {
            state.paginationCursors[currentLeague] = Cursors.update(
              cursor,
              state.paginationCursors[currentLeague],
              paginationCursors,
            );
          } else if (page) {
            state.paginationPages[currentLeague] = paginationPages;
          }
        }
      })
      .addCase(fetchMoreLeagueRecordings.rejected, (state, action) => {
        state.recordings[action.meta.arg.leagueId] = [
          ...state.recordings[action.meta.arg.leagueId],
        ];
        state.message = {
          title: 'Error',
          message: 'An error occurred while loading more recordings.',
          type: MessageType.ERROR,
        };
      })
      // deleteLeagueRecording cases
      .addCase(deleteLeagueRecording.pending, (state) => {
        state.status = APIStatus.PENDING;
      })
      .addCase(deleteLeagueRecording.fulfilled, (state, action) => {
        state.status = APIStatus.FULFILLED;
        state.message = {
          title: `${action.payload} deleted`,
          message: `The video "${action.payload}" has been removed from the League and is no longer visible to members`,
          type: MessageType.DELETED,
        };
      })
      .addCase(deleteLeagueRecording.rejected, (state, action) => {
        state.status = APIStatus.REJECTED;
        state.message = {
          title: 'Error',
          message: `An error occurred when trying to delete "${action.payload}". Try again later`,
          type: MessageType.ERROR,
        };
      })
      .addCase(editLeagueRecording.fulfilled, (state) => {
        state.status = APIStatus.FULFILLED;
        state.message = {
          title: 'Success!',
          message: 'Your details have been saved.',
          type: MessageType.UPDATED,
        };
      })
      .addCase(editLeagueRecording.rejected, (state) => {
        state.status = APIStatus.REJECTED;
        state.message = {
          title: 'Error',
          message: 'An error occured while saving the recording. Try again later',
          type: MessageType.ERROR,
        };
      })
      .addCase(postLeagueRecording.fulfilled, (state) => {
        state.status = APIStatus.FULFILLED;
        state.message = {
          title: 'Upload successful',
          message: 'Your video has been added to the League and is now visible to other league members',
          type: MessageType.UPDATED,
        };
      })
      .addCase(postLeagueRecording.rejected, (state) => {
        state.status = APIStatus.REJECTED;
        state.message = {
          title: 'Error',
          message: 'An error occurred while uploading recording. Try again later',
          type: MessageType.ERROR,
        };
      })
      .addCase(importRecordingToClub.rejected, (state, action) => {
        state.status = APIStatus.REJECTED;
        state.message = {
          title: 'Error',
          message: action.error.message ?? 'An error occurred while importing recording. Try again later',
          type: MessageType.ERROR,
        };
      })
      .addCase(importRecordingToClub.fulfilled, (state) => {
        state.status = APIStatus.FULFILLED;
        state.message = {
          title: 'Import successful',
          message: 'Your video has been imported to the selected club',
          type: MessageType.UPDATED,
        };
      });
  },
});

export const { clearRecordingMessage } = recordingsSlice.actions;

export const getRecordingsMessage = (state: IRootState) => state.recordingsReducer.message;

export const getCurrentLeagueRecordingsSelector = (state: IRootState) => {
  const { currentLeague } = state.leagueReducer;
  let recordings: any[] = [];
  if (currentLeague) {
    recordings = state.recordingsReducer.recordings[currentLeague.public_identifier];
  }

  return recordings;
};

export default recordingsSlice.reducer;
