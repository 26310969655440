import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { APIStatus } from '../enums/APIStatus';
import { IRootState } from '../redux/store';

export default <Item>(
  itemsSelector: (state: IRootState) => Item[],
  onMoreItems: () => void,
  triggerElement: React.MutableRefObject<any>,
  dependencies: React.DependencyList = [],
): [Item[], APIStatus] => {
  const items = useSelector(itemsSelector);
  const [status, setStatus] = useState<APIStatus>(APIStatus.IDLE);
  const [intersectionObserver, setIntersectionObserver] = useState<IntersectionObserver>();

  const intersectionObserverHandler: IntersectionObserverCallback = (entries) => {
    if (status === APIStatus.PENDING) {
      return;
    }

    entries
      .filter((entry) => entry.isIntersecting)
      .forEach(() => {
        setStatus(APIStatus.PENDING);
        onMoreItems();
      });
  };

  const createIntersectionObserver = () => {
    if (!triggerElement.current || !items) {
      return;
    }

    setIntersectionObserver(
      new IntersectionObserver(intersectionObserverHandler, {
        rootMargin: '0px',
        threshold: 1,
      }),
    );
  };

  useEffect(() => {
    setStatus(APIStatus.IDLE);
    intersectionObserver?.unobserve(triggerElement.current);
    createIntersectionObserver();
  }, dependencies);

  useEffect(() => {
    if (triggerElement.current) {
      intersectionObserver?.observe(triggerElement.current);
    }
  }, [intersectionObserver]);

  useEffect(() => {
    if (!triggerElement.current || !items) return;

    if (status === APIStatus.IDLE) {
      createIntersectionObserver();
    } else {
      setStatus(APIStatus.FULFILLED);
    }
  }, [items, triggerElement]);

  return [items, status];
};
